@import './src/core/ui/assets/css/variables';

.state-form {
    max-width: 410px;
    padding-bottom: 40px;
    clear: both;

    div.Mui-disabled, input:disabled {
        cursor: not-allowed;
    }

    .form-group {
        position: relative;
    }

    .info-override {
        font-size: 12px;
        position: relative;
        top: -20px;
        font-family: $font;
        text-transform: capitalize;
    }

    .header {
        .title {
            font-family: $font;
            font-size: 24px;
            //font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.67;
            letter-spacing: normal;
            color: $text;
        }
    }

    .body {
        min-width: 410px;
    }

    .btn-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        margin-top: 16px;
    }

    .duration {
        max-width: 85px;
        padding: 0 1px;

        &.form-field,
        &.form-fields {
            margin-bottom: 0;
        }

        .MuiInputBase-input {
            max-width: 60px;
        }
    }

    .time-group {
        margin-top: 16px;
        padding-bottom: 24px;

        .MuiFormControl-marginNormal {
            max-width: 100px;
            margin-top: 0;
            padding-left: 1px;

            &.calendar-picker {
                &-startDate {
                    label {
                        margin-left: -60px;
                    }
                }

                &-endDate {
                    label {
                        text-align: right;
                        width: 100%;
                    }
                }
            }

            #date-picker-end-date,
            #date-picker-start-date {
                max-width: 60px;
            }
        }

        label+.MuiInput-formControl {
            margin-top: 30px;
            border-radius: 1px;
            border: solid 1px $bar-grey;
        }

        .MuiInput-underline:before,
        .MuiInput-underline:after {
            content: none;
        }

        .MuiInputBase-root.MuiOutlinedInput-root fieldset {
            height: 45px;
            top: -5px;
            border-color: $bar-grey;
        }

        label.MuiFormLabel-root.MuiInputLabel-root {
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #333333;
            transform: scale(1);

            .MuiInputLabel-animated {}
        }

        .input.MuiInputBase-input {
            padding-left: 10px;
            padding-right: 10px;
        }

        .MuiIconButton-root:hover {
            background: transparent;
        }

        .MuiTouchRipple-root {
            display: none;
        }

        .MuiInputBase-root .MuiIconButton-root {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .category {
        &-wrap {}

        &-header {
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;
            letter-spacing: normal;
            color: $text;
            height: 40px;
        }

        &-body {
            padding: 9px 10px;
            border-radius: 1px;
            border: solid 1px $bar-grey;
        }
    }

    .color-circle {
        display: block;
        height: 18px;
        width: 18px;
        min-width: 18px;
        border-radius: 100%;
        margin-right: 10px;
    }

    .category-body {
        .MuiAccordion-root.Mui-expanded {
            margin: 0;
            box-shadow: none;
            // display: table;
            // width: 100%;
        }

        .MuiAccordion-root:before {
            content: unset;
            display: none;
        }

        .MuiAccordion-rounded {
            box-shadow: none;
        }

        .MuiAccordionSummary-root {
            padding: 0;
            display: table;
            min-height: 0;
            width: 100%;
        }

        .MuiAccordionSummary-content.Mui-expanded {
            margin: 0;
            display: table-row;

            svg {
                transform: rotateZ(0deg);
            }
        }

        .category-name-box {
            display: table-cell;
            vertical-align: middle;
            padding: 5px 0;
        }

        .category-name {
            display: flex;
            align-items: center;
        }

        .MuiAccordionSummary-content {
            margin: 0;
            display: table-row;
            height: 40px;
            align-items: center;

            svg {
                transform: rotateZ(-90deg);
                min-width: 24px;
            }
        }

        .MuiAccordionDetails-root {
            padding: 0;
        }

        .MuiList-root {
            width: 100%;
            display: table;
        }

        .MuiListItem-gutters {
            width: 100%;
            padding: 0;
            height: 40px;
            cursor: pointer;
            display: table-row;

            &:hover,
            &.selected {
                background-color: #4092f5;
                color: #FFFFFF;
            }

            &:hover {
                .wrap-menu-edit {
                    display: block;
                }
            }

            .category-sub-name {
                display: table-cell;
                vertical-align: middle;
                padding: 5px 0 5px 50px;
            }
        }
    }

    .category-line {
        display: flex;
        width: auto;
        height: 1px;
        flex: 1;
        margin-left: 10px;
        margin-right: 25px;
        background: #d0d4d6;
    }

    .wrap-name {
        opacity: 0.5;
        font-weight: bold;
    }

    textarea.comment {
        margin-top: 24px;
        width: 100%;
        padding: 11px 12px;
        min-height: 80px;
        height: auto !important;
        border: solid 1px $bar-grey;

        label {
            display: none;
        }
    }

    .search-wrap {
        border: solid 1px $bar-grey;

        .MuiInputBase-root {
            height: 37px;
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $text;
        }

        input::placeholder {
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: $text;
        }
    }

    .time-picker {
        height: 40px;
        margin-top: 30px;
        width: 60px;
        margin-bottom: 0;

        label {
            margin: 0;
        }

        .MuiFormControl-fullWidth {
            border: solid 1px $bar-grey;
            height: 40px;
        }

        input {
            -webkit-box-shadow: none;
            margin-left: 1px;
            margin-right: 1px;
            background: transparent;
            font-size: 13px;
            padding: 0;
            text-align: center;
        }

        span.react-time-picker__inputGroup__leadingZero {
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
        }
    }

    .wrap-menu-edit {
        display: none;

        .edit-dots.MuiButtonBase-root.MuiButton-root {
            height: 100%;
            background: transparent;
            width: auto;
            display: block;
            position: absolute;
            right: 8px;
            top: 0;

        }

        svg.config-dots {
            margin-right: -10px;
            cursor: pointer;
            fill: #FFFFFF;
        }
    }

    .form-group.error {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    form {
        padding-bottom: 120px;
    }

    .pinned-button-group {
        border-top: solid 1px #d0d4d6;
        position: fixed;
        bottom: 0;
        min-width: 410px;
        height: 120px;
        background-color: #fff;
        z-index: 2;
        padding-top: 15px;
        clear: both;

        &:after,
        &:before {
            content: '';
            width: 32px;
            height: 1px;
            background-color: #d0d4d6;
            position: absolute;
            top: -1px;
        }

        &:before {
            left: -32px;
        }

        &:after {
            right: -32px;
        }
    }
}

.MuiPickersDay-daySelected {
    background-color: #4092F5 !important;
}

#date-picker-end-date,
#date-picker-start-date {
    max-width: 152px;
    font-family: $font;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: $text;
    padding: 6px 0 7px 6px;
}

.MuiPopover-root {
    min-width: 960px;
}
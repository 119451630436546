.graph-selector {
    height: 100%;
    background-color: rgba(255, 0, 217, 0.07);
    position: absolute;
    z-index: 3;
    overflow: visible;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ff00d9;

    .start {
        position: absolute;
        top: 6px;
        left: -38px;
        width: 38px;
        background-color: rgba(247, 248, 249, 0.7);
    }

    .end {
        position: absolute;
        top: 6px;
        right: -60px;
        width: 55px;
        background-color: rgba(247, 248, 249, 0.7);
    }

    .duration {
        padding-top: 6px;
        width: 100%;
        height: 27px;
        border-left: 2px solid #ff00d9;
        border-right: 2px solid #ff00d9;
        background-color: rgba(247, 248, 249, 0.7);
        text-align: center;
        overflow: hidden;
    }

    &.border-left {
        .duration {
            border-right: none;
            border-left: 1px solid #ff00d9;
        }
    }

    &.border-1 {
        .duration {
            border-right: 1.5px solid #ff00d9;
            border-left: 1.5px solid #ff00d9;
        }
    }

    &.hover,
    &.select{
         .duration.bottom{
             overflow: visible;
             span{
                 min-width: 75px;
                 position: absolute;
                 transform: translate(-50%, 25px);
             }
         }
    }
    &.hover {
        opacity: 0.56;
    }
}
@import './src/core/ui/assets/css/variables';

.map-editor-footer-panel {
  position: absolute;
  bottom: 0;
  height: 7vh;
  user-select: none;
  touch-action: none;
  -webkit-user-drag: none;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  padding: 5px 20px 5px 50px;
  //border-top: 1px solid #e9ecef;

  & > button, & > button:hover {
    color: $text;
    right: 1vw;
    position: absolute;
  }

  &.ios-style-chrome,
  &.ios-style-safari {
    bottom: 10vh;

    @media screen and (max-width: 1300px) and (orientation: landscape){
      bottom: 23.5vh;
    }
  }

  &.android-style {
    bottom: 160px;

    @media screen and (max-width: 1300px) and (orientation: landscape){
      bottom: 100px;
    }
  }

  .MuiFormGroup-root {

    margin-left: 20px;

    .MuiTypography-root {
      font: 14px/1.5 "IBM Plex Sans", Arial, Helvetica, sans-serif;
    }
  }
}
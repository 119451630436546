@import "../../../../core/ui/assets/css/variables";

.state-table-wrapper {
    z-index: 4;
    width: 100%;
    position: absolute;
    left: 0px;
    pointer-events: none;

    .list .MuiTableBody-root .MuiTableRow-root.Mui-selected {
        height: 40px;
        box-shadow: inset 0 0 0 2px #4dadf7;
        border: solid 2px transparent;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            border: solid 2px #4dadf7;
            box-shadow: none;
        }
    }

    .state-table-header-component {
        z-index: 1;
        @media screen and (max-width: 1280px) {
            display: flex;
            align-items: center;
        }
    }

    .action-menu {
        background: #FFFFFF;
        border-radius: 100%;
    }

    .scroll-bar {
        & > div {
            z-index: 0;
        }
    }

    .action-menu {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            /* IE10+ CSS styles go here */
            transform: translateY(0);
        }
    }

    .search-field {
        button {
            pointer-events: none;
        }
    }

    .table-container {
        //min-width: 966px;
    }

    .list {
        margin-top: 15px;
    }

    .state-table {
        background-color: #e6eaed;
        padding: 20px 24px;
        pointer-events: all;
        position: relative;

        &:before {
            content: '';
            background-color: #e6eaed;
            top: 100%;
            right: 0;
            width: 100%;
            height: 200%;
            display: block;
            position: absolute;
        }

        .color-square {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            height: 40px;
            width: 5px;
        }

        .text-btn {
            cursor: pointer;
            height: 40px;
            margin-bottom: 8px;
        }

        .content-title {
            margin-bottom: 0px;
            display: inline-flex;
            width: 100%;
            max-width: 60%;
            padding: 0 35px;
            position: relative;

            @media screen and (max-width: 1280px) {
                font-size: 20px;
            }
            @media screen and (max-width: 1024px) {
                font-size: 16px;
                max-width: 50%;
            }
        }

        .list {
            .form-field {
                width: 240px;
                margin-bottom: 12px;
                position: absolute;
                top: 20px;
                right: 24px;
                @media screen and (max-width: 1280px) {
                    width: 180px;
                }
            }

            .MuiTableContainer-root {
                //overflow: visible;
                //right: 0;
            }

            tbody {
                .MuiTableCell-root {
                    white-space: nowrap;
                }
            }
        }

        .btn.export-btn {
            position: absolute;
            right: 264px;
            top: 16px;
            padding: 0;
            width: auto;
            min-width: auto;
            background: no-repeat;
            border: none;
            box-shadow: none;

            svg {
                width: 50px;
                height: 50px;
            }

            &:hover {
                background: no-repeat;
                border: none;
                box-shadow: none;
            }

            @media screen and (max-width: 1280px) {
                right: 200px;
            }
        }
    }

    .list thead {
        .MuiTableCell-root {
            padding: 0 12px;
            height: 21px;
        }
    }

    .list thead .MuiTableCell-root .column-action {
        position: absolute;
        top: 0;
        right: 12px;
    }

    [data-rtc-id="header-table"] {
        z-index: 3 !important;
    }

    .list .MuiTableBody-root {
        border-top: none;
    }

    .table-footer {
        th {
            height: 40px;
            position: sticky;
            bottom: -1px;
            font-weight: bold;
            background: #FFFFFF;
            line-height: 17px;

            &:before {
                content: '';
                height: 1px;
                position: absolute;
                top: 0;
                width: 100%;
                left: 0;
                background: #eeeeee;
            }

            &:after {
                content: '';
                height: 3px;
                position: absolute;
                bottom: -1px;
                left: 0;
                width: 100%;
                background: #FFFFFF;
            }
        }
    }
}

.filter-dialog {

    .MuiSvgIcon-fontSizeSmall {
        font-size: 1rem;
    }

    .dialog-popup {
        box-shadow: none;
        padding: 0px 20px 12px 20px;
        min-width: 250px;
        margin-top: -18px;

        .subheader {
            font-size: 12px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 3.33;
            letter-spacing: normal;
            text-align: left;
            height: 40px;
        }

        .filter-buttons {
            text-align: left;
            margin-bottom: 20px;

            .btn {
                color: #000;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: 0.15px;
                margin-right: 8px;
                padding: 0px 6px;
                min-width: 80px;

                &:hover {
                    color: #000;
                    box-shadow: none;
                    background-color: rgba(0, 0, 0, 0.04);
                }
            }
        }

        .filter-list {
            text-align: left;

            .MuiFormControlLabel-label {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 2.29;
                letter-spacing: normal;
            }

            .icon {
                border-radius: 3px;
                width: 16px;
                height: 16px;
                box-shadow: inset 0 0 0 1px rgba(16, 22, 26, .2), inset 0 -1px 0 rgba(16, 22, 26, .1);
                background-color: #f5f8fa;
                background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .8), hsla(0, 0%, 100%, 0));

                &.checked {
                    background-color: #1976d2;
                    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, 0));

                    &:before {
                        display: block;
                        width: 16px;
                        height: 16px;
                        content: '';
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
                    }
                }
            }
        }

    }
}

#dropdown-menu-state {
    ul.MuiList-root.MuiMenu-list.MuiList-padding {
        margin-top: 30px;
        background: #FFFFFF;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        padding: 16px 0;
    }

    .MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded {
        background: transparent;
        box-shadow: none;
        overflow: inherit;
    }

    li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
        padding-left: 24px;
        padding-right: 24px;
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.29;
        height: 32px;
        letter-spacing: normal;
        color: $text;
    }
}
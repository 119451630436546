@import '../../../assets/css/variables';

.panel {
    height: 30px;
    background-color: #f7f8f9;
    padding: 0 16px 0 8px;
    font-size: 12px;
    position: relative;
    z-index: 26;
    box-shadow: -1px 3px 3px -3px (rgba(0, 0, 0, 0.3));
    white-space: nowrap;

    .MuiListItem-root {
        width: auto;
    }

    .MuiListItem-gutters {
        padding: 0;
    }

    .MuiButtonBase-root {
        padding: 0;
    }

    .MuiFormGroup-root {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .grapics-item {
        margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            margin: 0 24px 0 0;
        }

        .MuiButtonBase-root {
            min-width: 30px;
        }
    }

    .histogram-item {
        margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            margin: 0 17px 0 0;
        }
    }

    .desc-item {
        margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            margin: 0 20px 0 0;
        }

        .MuiSvgIcon-root,
        .checkbox-icon {
            width: 20px;
            height: 20px;
        }
    }

    .minimap-item {
        margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            margin: 0 20px 0 0;
        }

        .MuiSvgIcon-root,
        .checkbox-icon {
            width: 20px;
            height: 20px;
        }

        .dashboard-mode {
            margin-left: 20px;
            display: table;
            line-height: 16px;
            border-collapse: separate;
            border-spacing: 5px;

            i {
                display: table-cell;
                border-radius: 50%;
                width: 16px;
                min-width: 16px;
                height: 16px;
                vertical-align: middle;
            }

            span {
                margin-top: -10px;
                display: table-cell;
                vertical-align: middle;
            }

            &.online {
                color: #75c36e;

                i {
                    background-color: #75c36e;
                }
            }

            &.offline {
                color: #fa5252;

                i {
                    background-color: #fa5252;
                }
            }
        }
    }

    .hmi-item {
        margin: 0 19px 0 0;

        @media(min-width: 1300px) {
            margin: 0 10px 0 0;
        }

        .MuiSvgIcon-root,
        .checkbox-icon {
            width: 20px;
            height: 20px;
        }

        &.check {
            .dropdown-arrow {
                transform: rotate(180deg);
            }
        }
    }

    .minimap-nav-item {
        margin: 0 10px 0 0;

        @media(min-width: 1300px) {
            margin: 0 18px 0 0;
        }

        li {
            padding: 0 5px;
            display: flex;
            align-items: center;

            @media(min-width: 1300px) {
                padding: 0 14px;
            }

            &:first-child,
            &:last-child {
                padding: 0;
            }

            &.minimap-nav-range-item {
                padding: 0;

                span {
                    padding: 0 5px;

                    @media(min-width: 1300px) {
                        padding: 0 14px;
                    }
                }

                &.active {
                    span {
                        background: #4092F5;
                        border-radius: 10px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        span {
            cursor: pointer;
            transition: 0.2s;
            display: flex;
            align-items: center;

            &:hover {
                opacity: 0.7;
            }
        }

    }

    .zoom-item {
        margin: 0 10px 0 0;
    }

    .histogram-type-item {
        margin: 0 10px 0 auto;

        @media(min-width: 1300px) {
            margin: 0 22px 0 auto;
        }
    }

    .export-item {
        margin: 0 10px 0 0;
    }

    .field-checkbox {
        margin: 0;

        .MuiTypography-body1 {
            font-size: 12px;
            line-height: 1;
        }
    }

    .datebox {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #74797d;

        .datebox-value {
            min-width: 92px;
        }
    }

    // Custom scroll
    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
        display: none !important;
    }

    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackX {
        width: 100% !important;
        height: 3px !important;

        &:hover {
            height: 5px !important;

        }
    }

    .ScrollbarsCustom-Wrapper {
        height: 100%;
        width: 100%;
    }

    .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbX {
        background: rgba(#4092F5, .4) !important;
    }
}
.button-panel {
    position: absolute;
    top: 30px;
    right: 20px;

    .MuiButton-root {
        margin: 0 10px;
        color: rgba(0, 0, 0, 0.54);
        min-width: auto;
        padding: 4px;
        width: 32px;
    }

    .MuiIconButton-root {
        padding: 4px;
    }
    .parameter-btn.active {
        background: #4092F5;
        border-color: #4092F5;
        svg {
            fill: #FFFFFF;
        }
    }
}

.schema-container {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;

    .canvas {
        position: relative;
    }
}

.player-title {
    padding: 0 10px;
}

.hmi-drawer-bottom {
    &>div {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
    }
}
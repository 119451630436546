.mini-map {
    height: 55px;
    background-color: #f6f7f8;
    width: 100%;
    position: relative;
    box-shadow: -1px 3px 3px -3px (rgba(0, 0, 0, 0.3));
    z-index: 3;
    overflow: hidden;

    .miniatures {
        width: 100%;
        height: 55px;
    }

    .brush {

        z-index: 3;
        position: absolute;
        width: 100%;
        height: 55px;
        top: 0px;
        left: 0px;

        .selection {
            stroke: none;
            fill: rgba(255, 255, 255, 0.2);
        }
    }

    .axis {
        position: absolute;
        height: 28px;
        width: 100%;
        pointer-events: none;

        &.top {
            top: 0px;
            left: 0px;
        }

        &.bottom {
            left: 0px;
            bottom: 0px;
        }

        .tick {
            position: absolute;
            border-left: 1px solid #adb5bd;
            overflow: hidden;
            top: 0px;
            box-sizing: border-box;
            font-size: 12px;
            color: #74797d;
            white-space: nowrap;

            p {
                padding: 5px 10px;
            }
        }
    }
}

path.domain {
    opacity: 0;
}
.form-btn-delete {
  color: #ff0000;
  cursor: pointer;
}

.map-editor-canvas.hmi {
  position: relative;

  .canvas-container {
    margin: 85px 20px 0 auto;
    border: 1px solid #000;
    overflow: hidden;
    border-radius: 6px;

    canvas {
      border-radius: 15px;
    }
  }
}

.map-editor {
  &.hmi {
    position: relative;
    //padding-top: 5px;
  }
}

.hmi-editor-container {
  .button-row {
    margin-top: 50px;
  }
}

.edit-object-form  {
  hr {
    margin: 0 0 20px;
  }
}

.autocomplete-field {
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0;
  }

  .MuiInputBase-root.MuiOutlinedInput-root fieldset {
    border-radius: 6px;
  }

  .MuiAutocomplete-endAdornment {
    z-index: 1;
    top: 3px;
  }

  &.success-field,
  &.error-field {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
      right: 30px;
    }
  }
  input::placeholder {
    font-style: normal !important;
  }
}
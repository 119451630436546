@import "../../../../core/ui/assets/css/variables";

text.title{
    &.states {
        font-size: 10px;
        letter-spacing: 0.9px;
        opacity: 0.4;
    }
}

text.alert-text{
    font-size: 10px;
    letter-spacing: 0.9px;
    opacity: 0.6;
}

text.comment{
    &.states {
        font-size: 10px;
        letter-spacing: 0.9px;
        font-style: italic;
        opacity: 0.4;
    }
}
g.state {
    cursor: pointer;

    .dropdown-menu {
        display: none;
        cursor: pointer;
    }

    &.active{
        .dropdown-menu {
            display: block;
        }
    }
}

div.state-chart {
    border-bottom: 1px solid #e9ecef;
    touch-action: manipulation;
    user-select: none;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
}

canvas.state-chart {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: -o-crisp-edges;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
}

.context-menu {
    .ul-wrap {
            min-width: 143px;
            padding: 16px 0;
        }

        a,
        li {
            height: 32px;
            font-family: $font;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 32px;
            letter-spacing: normal;
            color: $text;
            padding: 0 24px;
            max-width: 145px;
            p {
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: keep-all;
            }
        }
}
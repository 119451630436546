@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:300,400,400i,600,600i,700&display=swap');
@import 'variables';
@import 'icons';
@import 'form';

*,
*:before,
*:after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html,
body {
    height: 100%;
    background: #e6eaed;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;

    @viewport
,
@-ms-viewport,
@-o-viewport {
    width: 960px;
    zoom: 1;
}
}

body {
    min-width: 960px;
    font: 14px/1.5 $font;
    color: #333333;
    touch-action: manipulation double-tap-zoom;
    user-select: none;
    min-height: 100vh;

    /**
      Added to reset height on mobile
     */
    @media screen and (orientation: landscape) {
        height: 100vh;
        min-height: 100vh;
    }

    /**
      Added to reset height on mobile
     */
    @media screen and (orientation: portrait) {
        height: 100vh;
        min-height: 100vh;
    }

    @viewport
,
@-ms-viewport,
@-o-viewport {
    width: 960px;
    zoom: 1;
}

    &.login-page {
        min-width: 320px !important;

        @viewport
    ,
    @-ms-viewport,
    @-o-viewport {
        width: 320px;
        zoom: 1;
    }
    }
}

img,
iframe {
    display: block;
    max-width: 100%;
}

a {
    color: #4092f5;
    text-decoration: none;
}

.text-center {
    text-align: center;
}

strong,
b {
    font-weight: bold;
}

p,
ul,
ol,
blockquote {
    margin: 0 0 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1em;
}

h1 {
    font-size: 35px;
    line-height: 1.1;
    text-align: center;
    margin: 0 0 25px;
    font-weight: normal;

    @media(min-width: $md) {
        font-size: 48px;
        margin: 0 0 35px;
    }

    &.brand {
        color: $blue;
        font-size: 60px;
        font-weight: 300;
        margin: 0 0 30px;

        @media(min-width: $md) {
            font-size: 96px;
            margin: 0 0 58px;
        }
    }
}

h2 {
    font-size: 1.6em;
}

h3 {
    font-size: 1.4em;
}

h4 {
    font-size: 1.2em;
}

h5 {
    font-size: 1em;
}

h6 {
    font-size: 1em;
}

input[class^="MuiInput-input-"],
input[class*=" MuiInput-input-"] {
    height: 1.1875em;
}

#root {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    background: #e6eaed;
    height: calc(var(--vh, 1vh) * 100);

    &.with-sidebar {
        &:before {
            content: '';
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            width: 76px;
            background: #fff;
            //border-right: 1px solid #e9ecef;
            z-index: 1;
            pointer-events: none;
        }

        &.sidebar-visible {
            &:before {
                width: 320px;
            }
        }
    }
}

.login-page {
    min-height: 100%;

    #root {
        min-height: 100%;
        height: 100%;

        &.with-sidebar {
            &:before {
                display: none;
            }
        }
    }

    .brand-logo {
        margin: 0 0 30px 0;
    }
}

.media-type-mobile.login-page {
    @media screen and (orientation: landscape) {
        height: 100vh;
        min-height: 100vh;
    }

    .brand-logo {
        @media screen and (orientation: landscape) {
            margin: 0 0 10px 0;
            height: 120px;
        }
    }
}

.container {
    max-width: 1260px;
    padding: 0 15px;
    margin: 0 auto;
}

// auth sections
.auth-section {
    background: #e6eaed;
    // min-height: 100vh;
    display: flex;
    flex-grow: 1;
    align-items: center;
    text-align: center;
    padding: 40px 0;

    .container {
        width: 100%;
    }

    input,
    input:focus {
        caret-color: #999999;
        font-size: 16px;
        box-sizing: border-box;
        height: 37px;
    }
}

.content {
    background: #e6eaed;
    padding-left: 320px;
    //min-height: calc(100vh - 40px);
    //overflow-y: auto;
    height: 100%;

    .wrapper {
        padding: 28px 32px;

        //padding: 10px;
        .list {
            padding-top: 10px;
        }
    }
}

._loading_overlay_wrapper--active {
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: 1301;
}

.content-title {
    font-family: $font;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $text;
    margin-bottom: 16px;
}

.btn-wrap {
    button {
        margin-right: 20px;
    }
}

.MuiTypography-root.MuiFormControlLabel-label,
.MuiFormControl-root .MuiFormLabel-root {
    font-family: $font;
}

.with-list-component {
    .content-title {
        margin-bottom: 0;
        padding-right: 20px;

        &-wrap {
            display: flex;
            width: 100%;

            .message {
                flex: 1;
                max-width: inherit;

                .MuiSnackbarContent-root {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.expansion-panel {

    //.MuiCollapse-wrapper {
    //	height: 100%;
    //}
    //
    .MuiAccordion-root.Mui-expanded:last-child {
        margin-top: 0;
    }

    &.MuiPaper-root,
    .MuiPaper-root {
        background: transparent;
        box-shadow: none;
    }

    .MuiAccordionSummary-root {
        padding: 0;
        min-height: 40px;

        &.MuiAccordionSummary-root.Mui-expanded {
            min-height: 40px;
        }

        .MuiAccordionSummary-content {
            margin: 0;
            box-shadow: none;
        }

        .MuiAccordionSummary-root.Mui-expanded {
            min-height: inherit;
        }

        .MuiAccordionDetails-root {
            padding: 0;
            flex-direction: column;
        }

    }

    &.MuiAccordion-root.Mui-expanded {
        margin: 0;
        box-shadow: none;
    }

    .MuiAccordionDetails-root {
        padding: 0;
        flex-direction: column;
    }

    &.MuiAccordion-root:before {
        background: transparent;
    }

    .MuiAccordionDetails-root {
        display: block;

        &.factory-details {
            display: block;
        }
    }
}

.configuration-popover {
    .MuiGrid-root.MuiGrid-container {
        .MuiGrid-item:last-child {
            button {
                color: red;
            }
        }
    }
}

#dropdown-menu-state {
    & > div {
        touch-action: auto !important;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    ._loading_overlay_content {
        position: relative;
        top: 50%;
    }
}

.form-group.select {
    .MuiOutlinedInput-root,
    .select-box.MuiInputBase-root fieldset,
    .MuiSelect-outlined,
    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
        border-radius: 5px !important;
    }
}

.form-field .MuiFormLabel-root, .form-fields .MuiFormLabel-root {
    font-family: $font;
    font-size: 14px;
}

.tree-icon-item {
    height: 24px;
    width: 24px;
    display: inline-block;
}

body.media-type-mobile {
    min-height: -webkit-fill-available;

    #root {
        height: -webkit-fill-available;
    }
}

.form-add-edit-states,
.form-add-edit-states,
.user-form {
    h3 {
        font-size: 20.15px;
    }

    .common-error {
        margin-top: -15px;
        margin-bottom: 15px;
    }
}

.page-setting [data-rtc-id="header-table"] {
    z-index: 3 !important;
}

table.MuiTable-stickyHeader {
    border-collapse: collapse;
}

.node-form .MuiOutlinedInput-root, .node-form .select-box.MuiInputBase-root fieldset,
.node-form .MuiSelect-outlined,
.node-form.form-box .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-radius: 5px;
}

.default-icon-size {
    height: 24px;
    width: 24px;
    min-width: 24px;

    &.m8 {
        margin-right: 8px;
    }
    &.p4 {
        padding: 4px;
    }

    &-small {
        height: 12px;
        width: 12px;
        min-width: 12px;
    }
}

.display-flex {
    display: inline-flex;
}
@import "./src/core/ui/assets/css/variables";

.row-wide {
    min-width: 665px;

    .MuiGrid-spacing-xs-2 {
        margin: -4px -8px;

        > .MuiGrid-item {
            padding: 4px 8px;
            font-family: $font;
            color: $text;
        }

        > .field-content {
            width: calc(100% - 40px);
            font-family: $font;
            color: $text;
        }
    }

    .field-remove {
        .MuiIconButton-root {
            padding: 0px;
            margin: 8px 0;

        }
    }

    .field-sensor {
        width: 232px;
    }

    .field-oparands,
    .field-value,
    .field-time {
        width: 96px;
    }
    .field-value{
        width: 135px;
    }

    .filed-status {
        flex-grow: 1;

        .form-field {
            &.success-field {
                .list-multiple--header.MuiAccordionSummary-root.Mui-expanded, .list-multiple--header.MuiAccordionSummary-root {
                    border-color: #72c26a;
                }

            }

            &.Mui-disabled {
                &:after {
                    content: none;
                }
            }

            &.error-field {
                .list-multiple--header.MuiAccordionSummary-root.Mui-expanded, .list-multiple--header.MuiAccordionSummary-root {
                    border-color: #ff3b30;
                }
            }
        }
    }

    .field-comment {
        textarea {
            min-height: 71px;
            font-family: $font;
            font-size: 14px;
            color: $text;
        }
    }

    .field-checkbox {
        margin: 0;

        .MuiTypography-body1 {
            font-size: 14px;
        }
    }

    .field-email {
        width: 123px;
    }

    .btn-add-holder {
        padding-top: 20px;
        margin-bottom: 40px;
    }

    .button-row.flex-end {
        button {
            margin: 0 0 16px;
        }
    }
}
.select-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    min-width: 660px;

    &> div {
        min-width: 250px;
        margin-right: 16px;
    }

    label {
        font-family: $font;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: $text;
        margin-bottom: 10px;
        margin-top: 20px;
    }
}

.alert-form {
    max-width: 750px;

    .field-value-with-um {
        display: flex;
        width: 138px;
    }

    .inscription-um {
        z-index: 1;
        height: 39px;
        line-height: 39px;
        align-items: center;
        justify-content: center;
        //display: flex;
        padding: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &-wrapper {
            height: 39px;
            line-height: 39px;
            align-items: center;
            justify-content: center;
            display: inherit;
            max-width: 45px;
            padding: 0 0 0 5px;
        }
    }

    .tip,
    .row-subheader,
    .form-header-heavy {
        font-family: $font;
        color: $text;
    }
    label{
        font-family: $font;
        color: $text;
        font-size: 14px;
    }

    .select-wrap {
        margin-top: 10px;
    }
}
.drawer {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translate(-50%, -50%);
    z-index: 100;

    &.bottom {
        top: -10px;
        left: 50%;
        transform: translate(-50%, -50%) rotateZ(90deg);
    }

}

.full-screen-drawer {
    h3 {
        margin-bottom: 40px;
    }
}

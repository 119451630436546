.time-axis {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    pointer-events: none;
    overflow: hidden;

    .tick {
        position: absolute;
        border-left: 1px solid #adb5bd;
        overflow: hidden;
        top: 0px;
        box-sizing: border-box;
        text-align: center;
        font-size: 12px;
        color: #74797d;
        white-space: nowrap;

        p {
            padding: 5px 10px;

            span {
                padding-left: 10px;
                opacity: 0.4;
            }
        }
    }

    .dashed-line {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 1px;
        border-left: 1px dashed #adb5bd;
        z-index: 2;
        top: 28px;
        border-width: 1px;
        box-sizing: content-box;
    }
}

.time-axis-wrap {
    width: 100%;
    top: 30px;
    position: absolute;
    z-index: 2;
    height: calc(100% - 30px);
    pointer-events: none;
    //display: flex;
    //flex: 1;
    right: 0;


    &.active-mini-map {
        top: 85px;
        // height: calc(100% - 85px);
        height: auto;
        bottom: 0;
    }

    &:before {
        z-index: -1;
        content: '';
        height: 27px;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        display: block;
        background: #f6f7f8;
        box-shadow: -1px 3px 3px -3px (rgba(0, 0, 0, 0.3));
    }
}
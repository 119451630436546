@import "./src/core/ui/assets/css/variables";

$paddingLeft: 23px;
.header-sensor-list {
    [class~="MuiListItemText-root"] {
        .MuiTypography-body1 {
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            font-family: $font;
            color: #333333;
        }
    }

    &-right {
        height: 100%;
        display: flex;
        border-bottom: 1px solid rgba(51, 51, 51, 0.15);

        .description {
            &__idH {
                text-align: center;
            }

            &__umH,
            &__pit-codeH,
            &__commentH,
            &__valueH,
            &__targetvalueH {
                height: 100%;
                align-items: center;
                display: flex;
                margin: 0;
                padding-left: 15px;
                line-height: 47px;
                //border-right: 1px solid rgba(#adb5bd, 0.5);
            }

            &__idH {
                justify-content: center;
            }
        }
    }
}

.sensor {
    &__parameter {
        max-width: 320px;
        width: 100%;
        border-right: 1px solid #e9ecef;
        padding: 4px 4px 4px 12px;
    }

    &__description {
        flex: 1;
    }

    &__parameter,
    &__description {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;

        [class~="MuiListItemText-root"] {
            .MuiTypography-body1 {
                font-size: 14px;
                font-weight: normal;
                font-family: $font;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                color: $text
            }
        }
    }

}

.display-flex-row {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.left-padding {
    padding-left: $paddingLeft;

    &-4 {
        padding-left: 104px;
    }

    &-3 {
        padding-left: $paddingLeft * 3;
    }

    &-2 {
        padding-left: $paddingLeft * 2;
    }
}

.configure-draggable {
    background-color: #e6eaed;
    display: flex;
    height: calc(100vh - 40px);
    overflow: scroll;
    min-width: 100%;

    .expansion-panel .MuiAccordionSummary-root .MuiAccordionSummary-content {
        width: 100%;


        &:not(.Mui-expanded) {
            .description__targetvalue .MuiTypography-root {
                border-bottom: 1px solid #3f92f5;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    .ScrollbarsCustom-Track {
        z-index: 10;
    }

    &:after {
        content: "";
        position: fixed;
        max-width: 320px;
        width: 100%;
        display: block;
        left: 0;
        bottom: 16px;
        min-height: 100vh;
        background: #FFFFFF;
        //for IE 11
        min-width: 320px;
        line-height: 100%;
        z-index: 0;

    }

    &.is-mac-os {
        &:after {
            bottom: 0;
        }
    }

    [data-rbd-drag-handle-context-id="1"] {
        outline: none;
    }

    .sensor-list {
        position: relative;
        z-index: 3;

        &:last-child {
            padding-bottom: 40px;
        }

        .MuiCollapse-hidden {
            height: 0;
            display: none;

            .right-section {
                visibility: hidden;
                opacity: 0;
            }
        }

    .MuiCollapse-container {
      transition-delay: unset !important;
      transition-duration: unset !important;

      &.MuiCollapse-entered {
        transition-delay: unset !important;
        transition-duration: unset !important;

            }

        }
    }

    .sensor-list-content {
        position: relative;
        z-index: 2;

        & > div {
            position: relative;
            z-index: 2;
        }
    }

    .wrap-edit-menu {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        display: flex;
        width: 10px;
        z-index: 1225;

        &-node {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: flex;
            width: 10px;

            .config-dots {
                display: block;
                visibility: hidden;
            }

            .edit-dots.MuiButtonBase-root.MuiButton-root {
                color: $text;
                background: transparent;
                width: 10px;
            }
        }
    }

    .edit-dots {
        position: absolute;
        right: 4px;
        width: 10px;
    }

  .config-dots {
    cursor: pointer;
    display: none;
  }

    .item-title {
        cursor: pointer;

    &.display-flex{
      display: flex;
    }

    &.no-pointer {
      cursor: default;
    }
  }

    .sensor-item,
    .item-title {
        display: flex;
        height: 40px;
        align-items: center;
        width: 100%;
        //line-height: 40px;
        outline: none;
        /* правила ТОЛЬКО для Internet Explorer 10 и 11 */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            line-height: normal;
        }

        .setting-box--empty-space,
        .right-section {
            background: #fff;
            border-bottom: 1px solid #e9ecef;

            &.is-dragging {
                position: relative !important;
                background: #fff;

            }
        }

        .right-section.is-dragging +
        .left-section {
            .form-box-list-form.form-box-space.edit-sensor-list {
                background: #fff;
            }

            .description__targetvalue .MuiTypography-root {
                border-color: transparent;
            }
        }

        .setting-box--empty-space.lightgrey {
            background: #e6eaed;

            &.kpi,
            &.state {
                width: 492px;
                //width: calc(100% - 162px);
            }

            &.kpi,
            &.state,
            &.alert {
                background: #f5f6f7;
            }
        }

    }

    .sensor-item {
        .right-section:hover {
            background-color: $blue;
            border-right: 1px solid $blue;
            color: #fff;
            outline: none;

            .sensor {
                .st0 {
                    opacity: 0.7;
                }

                .st1 {
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    fill: #FFFFFF;
                }

                .st2 {
                    fill: #FFFFFF;
                }

                .st3 {
                    opacity: 0.5;
                    enable-background: new;
                }
            }

      .config-dots {
        display: flex;
        fill: #FFFFFF;
      }
    }

        .right-section:hover + .left-section {
            outline: none;
            position: relative;

            .edit-sensor-list:not(.target-form) {
                position: relative;

                &:before {
                    content: '';
                    box-shadow: inset 0 0 0 2px $blue;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    max-height: 40px;
                    z-index: 2;
                    pointer-events: none;
                }
            }
        }

    }

    .sensor-item.active {
        background-color: transparent;

        .config-dots {
            display: flex;
        }

        .right-section + .left-section {
            outline: none;
            position: relative;

            .edit-sensor-list:not(.target-form) {
                position: relative;

                &:before {
                    content: '';
                    box-shadow: inset 0 0 0 2px $blue;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    max-height: 40px;
                    z-index: 2;
                    pointer-events: none;
                }
            }
        }

        .left-section {
            outline: none;
            position: relative;
        }

        .right-section {
            background-color: $blue;
            border-right: 1px solid $blue;
            color: #fff;
            outline: none;

            .sensor {
                .st0 {
                    opacity: 0.7;
                }

                .st1 {
                    fill-rule: evenodd;
                    clip-rule: evenodd;
                    fill: #FFFFFF;
                }

                .st2 {
                    fill: #FFFFFF;
                }

                .st3 {
                    opacity: 0.5;
                    enable-background: new;
                }
            }
        }
    }

    .factory-item {
        //background: #fff;
        position: relative;
    }

    .left-section {
        outline: none;
        width: 100%;
        height: 40px;
        //margin-left: 320px;

        &.setting-box {
            display: flex;
            //justify-content: flex-end;
            align-self: stretch;
            width: 100%;
            //background: #f1f3f5;
        }

        .setting-box {
            &--empty-space {
                width: 578px;
                min-width: 578px;
                height: 40px;
                display: inline-flex;

                &::after {
                    content: "";
                    clear: both;
                    display: table;
                }
            }
        }

        //&.process {
        //    .setting-box {
        //        &--empty-space {
        //            border-top: 1px solid #e9ecef;
        //        }
        //    }
        //}


        &.lightgrey {
            background: #f1f3f5;
        }

        &.grey {
            background: #e6eaed;
        }
    }

    .right-section {
        max-width: 320px;
        min-width: 320px;
        display: flex;
        height: 40px;
        align-items: center;
        width: 100%;
        border-right: 1px solid #e9ecef;
        position: relative;
        //position: fixed;
        outline: none;
        font: $font;
        color: $text;
        z-index: 4;

        &-text {
            max-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -moz-box;
            -moz-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            box-orient: vertical;
            padding-right: 15px;
        }

        & > div {
            display: flex;
            flex: 1;
            height: 40px;
            line-height: 18px;
            overflow: hidden;
            align-items: center;
        }

        //&:focus,
        //&:active,
        &:hover {
            .wrap-edit-menu-node {
                .config-dots {
                    visibility: visible;
                }
            }
        }

    &:focus,
    &:active,
    &:hover,
    &.active {
      .edit-kpi-menu--wrap {
        button.edit-dots.MuiButtonBase-root.MuiButton-root {
          svg {
            display: flex;
          }
        }
      }
    }

        .wrap-edit-menu-node.active {
            .config-dots {
                visibility: visible;
            }
        }

        .dropdown-arrow {
            transform: rotate(-90deg);
            transition: ease 0.35s;
        }

        &.active {
            .dropdown-arrow {
                transform: rotate(0);
            }
        }
    }

    .setting-btn {
        padding-left: 15px;
        display: flex;
        align-items: center;
        background: #f5f6f7;
        border-bottom: 1px solid #e9ecef;
        //width: 148px;
        &.last-child {
            width: 144px;
            min-width: 144px;
        }
    }

    .first-item {
        padding-left: 8px;
        outline: none;
    }

    [class~="MuiListItemText-root"] {
        .MuiTypography-body1 {
            font-size: 14px;
            font-weight: normal;
            font-family: $font;
            font-stretch: normal;
            font-style: normal;
            line-height: 39px;
            letter-spacing: normal;
            outline: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $text;

            .form-field {
                display: flex;
            }

            &.border-bottom {
                border-bottom: 1px solid #3f92f5;
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    .header-sensor-list {
        min-width: 782px;
        flex-wrap: nowrap;

        [class~="MuiListItemText-root"] {
            .MuiTypography-body1 {
                font-weight: 600;
                white-space: inherit;
                line-height: 15px;
                text-align: center;
            }
        }

        .sensor-list-value {
            //margin-left: 320px;
        }

        .sensor__parameter {
            background: #fff;
            position: relative;
            //position: fixed;
            height: 48px;
            min-width: 320px;


            .MuiButtonBase-root.MuiButton-root .MuiButton-label,
            .btn .MuiButton-label {
                font-family: $font;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 2;
                letter-spacing: 0.15px;
                text-align: center;
                color: $blue;
                outline: none;
            }

            .btn {
                .MuiButton-label {
                    border: solid 1px $blue;
                    background-color: rgba(64, 146, 245, 0.1);
                    border-radius: 4px;
                    line-height: 22px !important;
                    max-height: 22px;
                    outline: none;
                    padding: 0 5px;
                }

                &.active {
                    box-shadow: none;

                    .MuiButton-label {
                        border: solid 1px rgba(0, 0, 0, 0.11);
                        background-color: #e6eaed;
                        color: $text;
                    }
                }
            }
        }

        .description {
            &__idH {
                width: 70px;
                max-width: 70px;
                min-width: 70px;
                margin: 0;
                line-height: 47px;
                //height: 39px;
                display: flex;
                align-items: center;

                .form-field {
                    display: flex;
                }
            }

            &__pit-codeH,,
            &__umH {
                width: 86px;
                max-width: 86px;
                min-width: 86px;

                .form-field {
                    display: flex;
                }
            }

            &__commentH {
                width: 192px;
                min-width: 192px;

                .form-field {
                    display: flex;
                }
            }

            &__valueH {
                width: 143px;
                max-width: 143px;
                min-width: 143px;
                text-align: right;

                .form-field {
                    display: flex;
                }
            }

            &__targetvalueH {
                width: 152px;
                max-width: 152px;
                min-width: 152px;
                text-align: right;
                display: flex;
                justify-content: flex-end;
                padding-right: 15px;

                .form-field {
                    display: flex;
                }
            }
        }
    }

    .process-item {
        position: relative;
    }

    .product-title-panel {
        padding: 7px 8px 0 0;
        z-index: 1;

        &.deactivate {
            &:after {
                border: 1px solid #74797d;
            }

            .product-title {
                background: #74797d;
            }

            .target-value-range {
                color: #74797d;
                background: rgba(#74797d, 0.2);
                border-bottom: 1px solid #74797d;
            }
        }
    }

    .product-title {
        background: #4092f5;
        font-size: 12px;
        line-height: 1;
        font-weight: 700;
        border-radius: 3px 3px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 0 3px 8px;
        color: #fff;

        p {
            margin: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .edit-dots.MuiButtonBase-root.MuiButton-root {
            top: 0;
            left: 0;
            height: 16px;
            width: 23px;
            background: transparent;
        }
    }

    .target-value-range {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        background: #d8e9fe;
        color: #4092f5;
        font-size: 11px;
        line-height: 1.2;
        font-style: italic;
        text-align: center;
        border: 1px solid #4092f5;

        li {
            flex-grow: 1;
            padding: 0 5px;
        }
    }

    .product-scheme {
        //position: static;

    }

    .product-select-wrap {
        &:after {
            content: '';
            position: absolute;
            top: 30px;
            bottom: 0;
            margin: 0 0 0 -8px;
            transform: translate(-100%);
            width: 12px;
            border-width: 1px 0 1px 1px;
            border-color: $blue;
            border-style: solid;
            left: 69px;
            z-index: 2;
        }
    }

    .product-scheme-title {
        color: $blue;
        font-weight: 600;
        font-style: italic;
        line-height: 1.43;
        display: flex;
        align-items: flex-end;
        align-self: stretch;
        padding: 20px 0 0 5px;

        //&:after {
        //    content: '';
        //    position: absolute;
        //    top: 30px;
        //    bottom: 0;
        //    margin: 0 0 0 -8px;
        //    transform: translate(-100%);
        //    width: 12px;
        //    border-width: 1px 0 1px 1px;
        //    border-color: $blue;
        //    border-style: solid;
        //    left: 69px;
        //    z-index: 2;
        //}
    }

    .list-draggable {
        position: relative;
    }

    .placeholder-props {
        max-height: 2px;
        background: #699be4;
        z-index: 1;
        max-width: 812px;

        &:before {
            z-index: 5;
            content: '';
            position: absolute;
            left: 0;
            display: block;
            width: 6px;
            height: 6px;
            background: #FFFFFF;
            border: solid 2px #699be4;
            border-radius: 100%;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.media-type-mobile {
    .configure-draggable {
        @media (orientation: landscape) {
            height: 100vh;

        }
        padding-bottom: 15px;
        //min-height: -webkit-fill-available;
    }
}

span.new-sensor {
    height: 12px;
    width: 12px;
    display: flex;
    position: absolute;
    right: 18px;
    background: $new-sensor;
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
}

.configure-draggable {
    .add-product {
        position: absolute;
        z-index: 3;
        right: 0;
    }


    .expansion-panel .MuiAccordionSummary-root.MuiAccordionSummary-root.Mui-expanded {
        position: initial;
    }

    .left-section.with-empty-space {
        display: flex;
    }
}

.edit-sensor-list {
    &.with-key-param {
        display: flex;
        //background: #f5f6f7;
        color: $battleship-grey;

        .description__id,
        .description__um,
        .description__pit-code,
        .description__comment,
        .description__value {
            //box-shadow: inset 0 -1px 0 0 #f1f3f5;
            //border-top: 1px solid rgba(51, 51, 51, 0.15);
            margin: 0;
            background: #f5f6f7;
        }
    }

    .description__targetvalue {
        &.empty {
            width: 0;
            max-width: 0;
            padding: 0;
        }

    }

}

.unsorted-wrapper {
    z-index: 23;
    position: relative;

    svg.key-param {
        display: none;
    }
}

.edit-product-menu {
    .MuiList-root.MuiMenu-list {
        padding: 16px 0;
    }

    li.MuiMenuItem-root {
        padding: 0 24px;
        font-size: 14px;
        line-height: 32px;
        font-family: $font;
        color: $text;
    }
}

.overflow-hidden {
    overflow: hidden;
}

.unit-panel.selected {
    &:after {
        content: '';
        position: absolute;
        display: flex;
        height: 100%;
        width: 898px;
        z-index: 5;
        top: 0;
        left: 0;
        border: solid 2px $blue;
        background-color: rgba(64, 146, 245, 0.08);
    }

    .right-section {
        box-shadow: inset 0 0 0 25px rgba(64, 146, 245, 0.08);
    }

  .item-title .right-section:not(.alerts) {
    border-top: solid 2px $blue;
  }

  .item-title .right-section.alerts {
    border-bottom: solid 2px $blue;
  }

  &:not(.Mui-expanded) {
    .item-title .right-section {
      border-bottom: solid 2px $blue;
    }
  }

  //.right-section.last-child {
  //  border-bottom: solid 2px $blue;
  //}
}

.MuiAccordionSummary-root.Mui-focused {
    background-color: inherit !important;
}

.edit-kpi-menu {

    &--wrap {
        position: absolute;
        right: 0;
        top: 0;

    button.edit-dots.MuiButtonBase-root.MuiButton-root {
      height: 40px;
      width: 10px;
      background: transparent;
    }
  }
}

.unsorted-config {
    ul {
        li:last-child {
            display: none;
        }
    }
}

.MuiCollapse-container.MuiCollapse-hidden {
    .item-title.no-pointer {
        //display: none;
        visibility: hidden;
    }

    .MuiCollapse-wrapper {
        //display: none;
        visibility: hidden;
    }
}

.MuiAccordion-root:before {
    content: none !important;
}

.configure-draggable .last-child {
    .sensor-item {
        .description__targetvalue .MuiTypography-root {

            border-bottom: 1px solid #3f92f5;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
        }
    }
}

.clone-when-drag {

    z-index: 22 !important;

    & > div {
        opacity: 0 !important;
    }
}

.clone-when-drag +
.left-section {
    z-index: 21;

    .form-box-list-form {
        form:first-child {
            opacity: 0 !important;
        }

        form {
            ul {
                opacity: 0;
            }
        }
    }
}

.configuration-tree-scroll {
    &.desktop {
        min-height: calc(100vh - 40px);
    }

    @media screen and (max-width: 1200px) and (orientation: landscape) {
        height: calc(100vh - 40px);
        //height: calc(100% - 40px) !important;
    }

    &.mobile {

        @media screen and (orientation: landscape) {
            height: 100vh;
            .sensor-list:last-child {
                padding-bottom: 0;
            }
        }

        @media screen and (orientation: portrait) {
            /* Portrait styles */
            height: 100vh;
        }

        &.ios {
            .sensor-list:last-child {
                padding-bottom: 0px;
            }
        }
    }


}

.configuration-tree-scroll.mobile +
.configure-draggable:after {
    bottom: 0;
    top: 0;
}

#root {
    &[aria-hidden="true"] {
        .configure-draggable {
            overflow: hidden;
            touch-action: none;
            position: fixed;
            top: 40px;
        }

        &.with-sidebar:before,
        &.with-sidebar.sidebar-visible:before,
        &.with-sidebar:after,
        &.with-sidebar.sidebar-visible:after {
            z-index: 1;
        }
    }
}

.hmi-list-component {
    &-wrap {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 2;

        .title-block {
            width: 320px;
            float: left;
            display: flex;
            align-items: center;
            line-height: 25px;
            border-bottom: 1px solid #e9ecef;
            background: #fff;
            -webkit-user-select: text;
            -ms-user-select: text;
            user-select: text;
        }

        .padding-1 {
            padding-left: 30px;
        }
    }

    &-body {

    }

    &-item {
        .left-section-hmi {
            width: 320px;
            align-items: center;
            display: flex;
            height: 40px;
            border-bottom: 1px solid #e9ecef;
            background: #fff;
            -webkit-user-select: text;
            -ms-user-select: text;
            user-select: text;
            padding-left: 5px;

            &:hover {
                .wrap-edit-menu-node {
                    .config-dots {
                        visibility: visible;
                    }
                }
            }

            &:focus,
            &:active,
            &:hover,
            &.active {
                .edit-kpi-menu--wrap {
                    button.edit-dots.MuiButtonBase-root.MuiButton-root {
                        display: flex;
                    }
                }
            }
        }
    }
}